import { useState } from 'react';
import ScrollTracker from '../ScrollTracker/ScrollTracker';
import ZoomPreviewComponent from './ZoomPreview';
import page1 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_1.jpg';
import page2 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_2.jpg';
import page3 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_3.jpg';
import page4 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_4.jpg';
import page5 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_5.jpg';
import page6 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_6.jpg';
import page7 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_7.jpg';
import page8 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_8.jpg';
import page9 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_9.jpg';
import page10 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_10.jpg';
import page11 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_11.jpg';
import page12 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_12.jpg';
import page13 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_13.jpg';
import page14 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_14.jpg';
import page15 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_15.jpg';
import page16 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_16.jpg';
import page17 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_17.jpg';
import page18 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_18.jpg';
import page19 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_19.jpg';
import page20 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_20.jpg';
import page21 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_21.jpg';
import page22 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_22.jpg';
import page23 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_23.jpg';
import page24 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_24.jpg';
import page25 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_25.jpg';
import page26 from '../../assets/img/agreement/5ANO6p-Perjanjian_Kerjasama_26.jpg';

const images = [
  page1,
  page2,
  page3,
  page4,
  page5,
  page6,
  page7,
  page8,
  page9,
  page10,
  page11,
  page12,
  page13,
  page14,
  page15,
  page16,
  page17,
  page18,
  page19,
  page20,
  page21,
  page22,
  page23,
  page24,
  page25,
  page26,
];

const ModalAgreement = ({ handleReadAgreement }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  function handleUpdateScrollPercentage(value) {
    setScrollPercentage(value);
  }

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">
              Agreement
            </h1>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ScrollTracker handleIndicator={handleUpdateScrollPercentage}>
              <div className="agreement-container">
                {images.map((image, index) => (
                  <ZoomPreviewComponent>
                    <img
                      src={image}
                      alt={`page-${index + 1}`}
                      className="agreement-img"
                    />
                  </ZoomPreviewComponent>
                ))}
              </div>
            </ScrollTracker>
          </div>
          <div className="modal-footer">
            <button
              disabled={scrollPercentage <= 98.99}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => handleReadAgreement(true)}
            >
              Understand
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAgreement;
