/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { getListProject } from '../../../helper/function';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import SwiperInstance from 'swiper';
import LoadingComponent from '../../../atoms/LoadingComponent';
import PaginationComponent from '../../../organism/DataTable/CustomDataTablePagination';
import moment from 'moment';
import 'moment/locale/id';
import $ from 'jquery';
$.datepicker = require('bootstrap-datepicker');

const Portfolio = (props) => {
  const [navSwiper, setNewsSwiper] = useState();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [project, setProject] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(0);
  const [dateFilter, setDateFilter] = useState('');

  // Pagination state
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [totalNewOrder, setTotalNewOrder] = useState(0);

  const getNewOrderlist = async () => {
    const payload = {
      grupbyOrder: 'Y',
      search: '',
      status: 1,
      date: '',
      offset: 0,
      limit: 10,
    };

    const response = await getListProject(payload);

    if (response?.message === 'The record was not found') {
      setTotalNewOrder(0);
      return;
    }

    if (response?.message === 'Success') {
      setTotalNewOrder(response.data.length);
      return;
    }
  };

  const getListPortfolioData = async () => {
    // Set loading
    setIsLoadingData(true);

    const payload = {
      grupbyOrder: 'Y',
      search: searchFilter,
      status: statusFilter,
      date: dateFilter,
      offset: page,
      limit: limit,
    };
    const response = await getListProject(payload);
    if (response.message === 'Success' && response.meta.total > 0) {
      setProject(response.data);
      setTotal(response.meta.total);
      // Set loading
      setIsLoadingData(false);
    } else {
      setProject([]);
      // Set loading
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getListPortfolioData();

    $('#datepicker').datepicker({
      templates: {
        leftArrow: '<img src="../../dist/img/custom/chevron-left.svg"></img>',
        rightArrow: '<img src="../../dist/img/custom/chevron-right.svg"></img>',
      },
    });

    $('#datepicker').on('changeDate', function (e) {
      let date = $('#date-field').val();
      setDateFilter(
        moment(date?.replaceAll('/', '-'), 'MM/DD/YYYY').format('YYYY-MM-DD')
      );
    });

    // Slider
    if (!navSwiper) {
      let swiperInstance = new SwiperInstance('.swiper-nav', {
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      setNewsSwiper(swiperInstance);
    }
  }, []);

  // For Filter & pagination
  useEffect(() => {
    getListPortfolioData();
  }, [searchFilter, statusFilter, dateFilter, page, limit]);

  //
  const handleChangeStatus = (value) => {
    setSearchFilter('');
    setStatusFilter(value);
    setDateFilter('');
    setPage(0);
    setLimit(10);
    $('#search-field').val('');
  };

  //
  const handleSearchData = (e) => {
    if ($(e.currentTarget).prop('id') === 'button-search') {
      setPage(0);
      setLimit(10);
      setSearchFilter(
        $(e.currentTarget).parent().siblings('input#search-field').val()
      );
    }

    if ($(e.currentTarget).prop('id') === 'search-field') {
      $(e.currentTarget).on('keyup', function () {});
    }
  };

  const handleSearchDataOnInput = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      setLimit(10);
      setSearchFilter($('input#search-field').val());
    }
  };

  //
  const handleResetFilter = () => {
    setDateFilter('');
    setSearchFilter('');
    setLimit(10);

    $(document).find('#all-status-tab').trigger('click');
    $('#search-field').val('');
    $('#date-field').val('');
  };

  //
  const onChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    getNewOrderlist();
  }, []);

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="mb-4 px-4 px-md-0">
        <Link to="/designer/project" className="fs-6 fw-bold">
          Project List
        </Link>
      </div>

      <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
        <div className="w-100 swiper-nav swiper">
          <ul
            className="nav nav-pills ff2 nav-wrap swiper-wrapper"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link active"
                id="all-status-tab"
                data-bs-toggle="pill"
                data-bs-target="#all-status-temp"
                type="button"
                role="tab"
                aria-controls="all-status"
                aria-selected="true"
                onClick={() => handleChangeStatus(0)}
              >
                All Status
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link new-project"
                id="newproject-tab"
                data-bs-toggle="pill"
                data-bs-target="#newproject"
                type="button"
                role="tab"
                aria-controls="newproject"
                aria-selected="false"
                onClick={() => handleChangeStatus(1)}
              >
                <div>New Project</div>

                {totalNewOrder > 0 ? (
                  <div className="label-status-notification">
                    <FontAwesomeIcon icon={faStarOfLife} />
                  </div>
                ) : null}
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link"
                id="onsetup-tab"
                data-bs-toggle="pill"
                data-bs-target="#onsetup"
                type="button"
                role="tab"
                aria-controls="onsetup"
                aria-selected="false"
                onClick={() => handleChangeStatus(2)}
              >
                On Setup
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link"
                id="createconcept-tab"
                data-bs-toggle="pill"
                data-bs-target="#createconcept"
                type="button"
                role="tab"
                aria-controls="createconcept"
                aria-selected="false"
                onClick={() => handleChangeStatus(3)}
              >
                Create Concept
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link"
                id="final-design-tab"
                data-bs-toggle="pill"
                data-bs-target="#final-design"
                type="button"
                role="tab"
                aria-controls="final-design"
                aria-selected="false"
                onClick={() => handleChangeStatus(4)}
              >
                Final Design
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link"
                id="cancelled-tab"
                data-bs-toggle="pill"
                data-bs-target="#cancelled"
                type="button"
                role="tab"
                aria-controls="cancelled"
                aria-selected="false"
                onClick={() => handleChangeStatus(5)}
              >
                Cancelled
              </button>
            </li>
            <li className="nav-item swiper-slide" role="presentation">
              <button
                className="nav-link"
                id="finaldesign-tab"
                data-bs-toggle="pill"
                data-bs-target="#finaldesign"
                type="button"
                role="tab"
                aria-controls="finaldesign"
                aria-selected="false"
                onClick={() => handleChangeStatus(6)}
              >
                Completed
              </button>
            </li>
          </ul>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="all-status"
          role="tabpanel"
          aria-labelledby="all-status-tab"
        >
          {/* <!-- Ada Isinya --> */}
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center mb-4">
            <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
              <div className="mb-2 mb-lg-0">
                <div
                  className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-lg-100"
                  style={{ width: '235px' }}
                >
                  <input
                    type="text"
                    className="form-control border-0 fz-14 box-shadow-focus-0"
                    placeholder="Search by order number"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    id="search-field"
                    onKeyDown={handleSearchDataOnInput}
                  />
                  <div className="py-1 px-1">
                    <button
                      onClick={handleSearchData}
                      className="btn btn-secondary border-0 rounded-circle d-flex justify-content-center"
                      type="button"
                      id="button-search"
                      style={{ width: '1.8rem', height: '1.7rem' }}
                    >
                      <img
                        src="../../dist/img/custom/search.svg"
                        style={{ width: '1rem' }}
                        className="filter-white"
                        alt="img"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
              <div
                className="input-group bg-white border rounded password date w-lg-100"
                id="datepicker"
              >
                <input
                  type="text"
                  className="form-control border-0 box-shadow-focus-0"
                  placeholder="Date"
                  aria-describedby="basic-addon1"
                  id="date-field"
                />
                <span className="input-group-append">
                  <span
                    className="input-group-text bg-white border-0"
                    id="basic-addon1"
                  >
                    <img
                      src="../../dist/img/custom/calendar.svg"
                      className="icon-3 pt-1"
                      alt="img"
                    ></img>
                  </span>
                </span>
              </div>
            </div>
            <div className="ps-0 ps-lg-4 w-lg-100 mt-2 mt-lg-0 border-start border-lg-0">
              <button
                className="btn btn-line fw-bold w-lg-100"
                onClick={handleResetFilter}
              >
                Reset
              </button>
            </div>
          </div>

          {/* <!-- Project List --> */}
          {project.length > 0 && isLoadingData === false && (
            <div className="w-100 d-flex flex-column">
              {project.map((item, index) => {
                return (
                  <div key={index} className="card card-project">
                    <div className="card-body p-5">
                      <div className="row item">
                        <div className="col-12 col-md-6">
                          <div className="d-flex justify-content-around align-items-start">
                            <img
                              src="../../dist/img/empty/empty-product.png"
                              className="item-picture"
                              alt="img"
                            />
                            <div className="d-flex flex-column ms-4">
                              <dl>
                                <dt>Order No.</dt>
                                <dd>
                                  <span className="fs-5 fw-bold">
                                    {item.orderNo}
                                  </span>
                                </dd>
                              </dl>

                              {(item.status === 'New Project' ||
                                item.status === 'Cancelled') && (
                                <NavLink
                                  to={`view/${item.orderNo}/${item.id}`}
                                  className="badge-new badge-yellow"
                                >
                                  View
                                </NavLink>
                              )}

                              {item.status !== 'New Project' &&
                                item.status !== 'Cancelled' && (
                                  <NavLink
                                    to={`view/${item.orderNo}`}
                                    className="badge-new badge-yellow"
                                  >
                                    View
                                  </NavLink>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="row row-cols-2 px-5">
                            <dl className="col mb-0">
                              <dt>CLIENT</dt>
                              <dd>{item.client}</dd>
                              <dt>SERVICE</dt>
                              <dd>{item.service}</dd>
                            </dl>
                            <dl className="col mb-0">
                              <dt>LAST UPDATE</dt>
                              <dd>{item?.updateAt}</dd>
                              <dt>STATUS</dt>
                              <dd>
                                <div className="badge-new badge-gray">
                                  {item.status}
                                </div>
                                {/* {(item.status === 'New Project') && (
                                                                    <div className="badge-new badge-green">{item.status}</div>
                                                                )}
                                                                {(item.status === 'On Setup') && (
                                                                    <div className="badge-new badge-gray">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Create Concept') && (
                                                                    <div className="badge-new badge-yellow">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Final Design') && (
                                                                    <div className="badge-new badge-yellow">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Cancelled') && (
                                                                    <div className="badge-new badge-red">{item.status}</div>
                                                                )}
                                                                {(item.status === 'Complete') && (
                                                                    <div className="badge-new badge-blue">{item.status}</div>
                                                                )} */}
                              </dd>
                              {/* <dd><div className="badge-new badge-yellow">Create Concept</div></dd>
                                                  <dd><div className="badge-new badge-gray">On Setup</div></dd>
                                                  <dd><div className="badge-new badge-green">Complete</div></dd>
                                                  <dd><div className="badge-new badge-red">Cancelled</div></dd> */}
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* Paging */}
          {project.length > 0 && (
            <PaginationComponent
              total={total}
              page={page}
              limit={limit}
              startPage={0}
              totalCurrent={project.length}
              setPage={onChangePage}
              isLoadingData={isLoadingData}
            />
          )}

          {/* Project not found */}
          {project.length === 0 && isLoadingData === false && (
            <div
              className="d-flex flex-row align-items-center h-100"
              style={{ marginTop: '75px' }}
            >
              <div className="align-self-center mx-auto">
                <div
                  className="img-wrap"
                  style={{ width: '300px', height: '300px' }}
                >
                  <img src="../dist/img/empty/empty-product.png" alt="img" />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3 fw-bold">
                  No Project Found!
                </div>
              </div>
            </div>
          )}

          {/* Loading */}
          {isLoadingData === true && <LoadingComponent />}
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Portfolio);
