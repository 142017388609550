/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import {
  getRoomType,
  getCategory,
  getSubCategory,
  getSubSubCategory,
  addItems,
  detailProduct,
  variantList,
  editItems,
  addVariantStock,
  getVendorBrand,
  deleteVariant,
} from '../../helper/function';
import {
  formatMoney,
  formatTime,
  getBase64FromUrlV3,
  getFileExtension,
} from '../../helper/lib';

import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-time-picker/dist/TimePicker.css';

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import $ from 'jquery';
import { IsAlert } from '../../atoms/IsAlert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FileUpload from '../../organism/FileUpload/FileUpload';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const AddItem = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const listVarianOnly = queryParameters.get('list-varian-only');

  const { id } = useParams();
  const location = useLocation();
  const [isShowVariant, setIsShowVariant] = useState(
    location?.state?.isShowVariant
  );

  const [isEdit, setIsEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [showUploadInput, setShowUploadInput] = useState(true);
  const [samplePreview, setSamplePreview] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [variants, setVariants] = useState([]);
  const [searchVariant, setSearchVariant] = useState('');

  const [name, setName] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaKeyword, setMetaKeyword] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [roomType, setRoomType] = useState([]);
  const [roomTypeOptions, setRoomTypeOptions] = useState([]);
  const [roomTypeSelectedLabel, setRoomTypeSelectedLabel] = useState('');
  const [category, setCategory] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState('');
  const [subSubCategoryOptions, setSubSubCategoryOptions] = useState([]);

  const [sku, setSku] = useState('');
  const [selectedWarrantyOwner, setSelectedWarrantyOwner] = useState(0);
  const [warrantyPeriod, setWarrantyPeriod] = useState(null);
  const [warrantyDescription, setWarrantyDescription] = useState(null);
  const [dimension1, setDimension1] = useState();
  const [dimension2, setDimension2] = useState();
  const [dimension3, setDimension3] = useState();
  const [packageDimension1, setPackageDimension1] = useState();
  const [packageDimension2, setPackageDimension2] = useState();
  const [packageDimension3, setPackageDimension3] = useState();
  const [weight, setWeight] = useState();
  const [productWeight, setProductWeight] = useState();
  const [diameter, setDiameter] = useState('');
  const [roomTypeListTemp, setRoomTypeListTemp] = useState([]);

  const [price, setPrice] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState(1);
  const [discount, setDiscount] = useState();
  const [discountDateFrom, setDiscountDateFrom] = useState(null);
  const [discountDateTo, setDiscountDateTo] = useState(null);
  const [discountTimeFrom, setDiscountTimeFrom] = useState('');
  const [discountTimeTo, setDiscountTimeTo] = useState('');

  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [isPreOrder, setIsPreOrder] = useState(false);
  const [poDuration, setPoDuration] = useState();
  const [isFragile, setIsFragile] = useState(false);
  const [isVolumetric, setIsVolumetric] = useState(false);
  const [woodPackage, setWoodPackage] = useState(false);

  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');

  const [inputAddStock, setInputAddStock] = useState(0);
  const [idVarianceAddStock, setIdVarianceAddStock] = useState(0);

  const [tsx, setTsx] = useState(Date.now());

  const handleClickAddStock = (idVariance) => {
    setInputAddStock(0);
    setIdVarianceAddStock(idVariance);
  };

  const handleClickDeleteVariant = (idVariance) => {
    IsAlert.fire({
      title: 'Confirm',
      text: 'Delete this variant?',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirm',
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        props.setLoading(true);
        try {
          let response = await deleteVariant(idVariance);
          if (response?.message === 'The record was deleted') {
            IsAlert.fire({
              title: 'Success',
              text: 'Delete variant success.',
              icon: 'success',
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText: 'Confirm',
            }).then(function (isConfirm) {
              if (isConfirm.isConfirmed) {
                getVariants();
              }
            });
          } else {
            IsAlert.fire({
              title: 'Failed',
              text: response?.message ?? 'Something when wrong.',
              icon: 'error',
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText: 'Confirm',
            });
          }
        } catch (error) {
          IsAlert.fire({
            title: 'Failed',
            text: 'Something when wrong.',
            icon: 'error',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          });
        }
        props.setLoading(false);
      }
    });
  };

  const handleAddStock = async () => {
    props.setLoading(true);
    let payloadAddStock = {
      variance_id: idVarianceAddStock,
      product_id: id,
      new_stock: inputAddStock,
    };

    let responseAddStock = await addVariantStock(payloadAddStock);
    if (responseAddStock?.message === 'Success') {
      getVariants();
      IsAlert.fire({
        title: 'Success',
        text: 'Add stock success.',
        icon: 'success',
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Confirm',
      });
    } else {
      IsAlert.fire({
        title: 'Failed',
        text: responseAddStock?.message ?? 'Something when wrong.',
        icon: 'error',
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Confirm',
      });
    }
    props.setLoading(false);
    return;
  };

  const navigate = useNavigate();
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Add the tag to the list of tags
      setTags([...tags, inputValue.trim()]);
      // Clear the input field
      setInputValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleRoomTypeChange = (roomType) => {
    setRoomTypeSelectedLabel(roomType?.label);
    setRoomType(roomType);
  };

  const handleCategoryChange = (category) => {
    // Clear input sub categories
    setSubCategory('');
    setSubSubCategory('');
    setSubCategoryOptions([]);
    setSubSubCategoryOptions([]);

    setCategory(category);
    getSubCategoryData(category.value);

    let selectedCategory = categories
      .filter(function (item) {
        return item.id === category.value;
      })
      .pop();

    // Set dimension
    setDimension3(selectedCategory?.categoryProductDimensions[0]?.dimension1);
    setDimension1(selectedCategory?.categoryProductDimensions[0]?.dimension2);
    setDimension2(selectedCategory?.categoryProductDimensions[0]?.dimension3);
    setPackageDimension3(
      selectedCategory?.categoryPackageDimensions[0]?.packageDimension1
    );
    setPackageDimension1(
      selectedCategory?.categoryPackageDimensions[0]?.packageDimension2
    );
    setPackageDimension2(
      selectedCategory?.categoryPackageDimensions[0]?.packageDimension3
    );
  };

  const handleSubCategoryChange = (subCategory) => {
    // Clear input sub categories
    setSubSubCategory('');
    setSubSubCategoryOptions([]);

    setSubCategory(subCategory);
    getSubSubCategoryData(subCategory.value);
  };

  const handleSubSubCategoryChange = (subSubCategory) => {
    setSubSubCategory(subSubCategory);
  };
  // const handleUpload = async (event) => {
  //   // Validate size & type file
  //   let availableExtension = ['jpeg', 'jpg', 'png'];
  //   let extension = event.target.files[0].name.split('.').pop().toLowerCase();
  //   let size =
  //     Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100;

  //   // Validate Size
  //   if (size > 5) {
  //     toast.warning('Max file size 5MB.');
  //     return;
  //   }

  //   // Validate Extension
  //   if (availableExtension.includes(extension) === false) {
  //     toast.warning(
  //       'File extension not allowed, allowed extension: .jpeg, .jpg, & .png'
  //     );
  //     return;
  //   }

  //   const previewImage = URL.createObjectURL(event.target.files[0]);
  //   setPreviewImage(previewImage);
  //   const base64 = await convertBase64(event.target.files[0]);
  //   setSamplePreview([
  //     ...samplePreview,
  //     {
  //       name: event?.target?.files[0]?.name,
  //       size: Math.round((event?.target?.files[0]?.size / 1000000) * 100) / 100,
  //       preview: previewImage,
  //       file: base64.replace(/^data:.+;base64,/, ''),
  //     },
  //   ]);
  //   setPreviewImage(samplePreview);
  // };

  const handleUpload = async (files) => {
    if (samplePreview.length + files.length > 10) {
      IsAlert.fire({
        title: 'Error',
        text: 'Maximum Upload 10 Files',
        icon: 'error',
      });

      return;
    }
    files.forEach(async (file) => {
      const fileExtension = await getFileExtension(file.name);
      const fsize = Math.round((file.size / 1000000) * 100) / 100;
      if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
        // Alert
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload file with the following extension only: jpeg, jpg, png',
          icon: 'error',
        });

        return;
      }

      if (fsize > 40.0) {
        IsAlert.fire({
          title: 'Error',
          text: 'Please upload image with a size of 40 MB or less per file',
          icon: 'error',
        });
        return;
      }

      const previewImage = URL.createObjectURL(file);
      const base64 = await convertBase64(file);

      setSamplePreview((prev) => [
        ...prev,
        {
          name: file.name,
          size: fsize,
          preview: previewImage,
          file: base64,
        },
      ]);

      return;
    });
  };

  const convertBase64 = async (file, isBlob) => {
    let blob = file;
    if (isBlob) {
      blob = await file.blob();
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearImageSelected = (index) => {
    const filtered = samplePreview.filter((_, i) => {
      return i !== index;
    });
    setSamplePreview(filtered);
  };

  const getRoomTypeData = async () => {
    const response = await getRoomType();
    if (response.message === 'Success' && response.meta.total > 0) {
      let options = [];
      response.data.map((item, index) => {
        options.push({ value: item.id, label: item.room_type });
        return;
      });
      setRoomTypeOptions(options);
    }
  };

  const getCategoryData = async () => {
    const response = await getCategory();
    if (response.message === 'Success' && response.meta.total > 0) {
      let options = [];
      response.data.map((item, index) => {
        options.push({ value: item.id, label: item.name });
        return;
      });
      setCategoryOptions(options);
      setCategories(response.data);
    }
  };

  const getSubCategoryData = async (idCategory = '') => {
    const response = await getSubCategory(idCategory);
    if (response.message === 'Success' && response.meta.total > 0) {
      let options = [];
      response.data.map((item, index) => {
        options.push({ value: item.id, label: item.name });
        return;
      });
      setSubCategoryOptions(options);
    }
  };

  const getSubSubCategoryData = async (idSubCategory = '') => {
    const response = await getSubSubCategory(idSubCategory);
    if (response.message === 'Success' && response.meta.total > 0) {
      let options = [];
      response.data.map((item, index) => {
        options.push({ value: item.id, label: item.name });
        return;
      });
      setSubSubCategoryOptions(options);
    }
  };

  const getDetailProduct = async () => {
    const response = await detailProduct(id);
    if (response.message === 'Success' && response.meta.total > 0) {
      // set data
      setName(response?.data?.name);
      setMetaTitle(response?.data?.meta_title);
      setMetaKeyword(response?.data?.meta_keyword);
      setMetaDescription(response?.data?.meta_description);
      setSku(response?.data?.sku);
      setPrice(formatMoney(response?.data?.price, ''));
      setSelectedDiscountType(
        response?.data?.discount_type === 0 ? 1 : response?.data?.discount_type
      );
      setDiscount(
        response?.data?.discount_type === 0 ||
          response?.data?.discount_type === 1
          ? formatMoney(response?.data?.discount, '')
          : response?.data?.discount
      );
      setDiscountDateFrom(
        response?.data?.discount_active_from
          ? moment(response?.data?.discount_active_from).toDate()
          : null
      );
      setDiscountDateTo(
        response?.data?.discount_active_to
          ? moment(response?.data?.discount_active_to).toDate()
          : null
      );
      if (response?.data?.discount_active_from) {
        setDiscountTimeFrom(
          formatTime(new Date(response?.data?.discount_active_from))
        );
      }
      if (response?.data?.discount_active_to) {
        setDiscountTimeTo(
          formatTime(new Date(response?.data?.discount_active_to))
        );
      }
      setIsPreOrder(response?.data?.is_preorder);
      setIsFragile(response?.data?.is_fragile);
      setPoDuration(response?.data?.po_duration);
      setIsVolumetric(response?.data?.is_volumetric);
      setWoodPackage(response?.data?.wood_package);
      setRoomTypeSelectedLabel(response?.data?.room_type);
      setSelectedWarrantyOwner(
        response?.data?.product_details[0]?.warranty_type
      );
      setWarrantyPeriod(response?.data?.product_details[0]?.warranty_period);
      setWarrantyDescription(
        response?.data?.product_details[0]?.warranty_description
      );
      setSelectedBrand({
        value: response?.data?.brand?.id,
        label: response?.data?.brand?.name,
        img: response?.data?.brand?.image_url,
        tags: '',
        checked: true,
      });
      setRoomTypeListTemp(response?.data?.room_type_list);

      // Set Room Type List
      let dataSelected = [];
      response?.data?.room_type_list?.forEach((roomTypeData) => {
        let findRoom = roomTypeOptions.find(
          (item) => item.label === roomTypeData
        );
        if (findRoom) {
          dataSelected.push(findRoom);
        }
      });
      setRoomType(dataSelected);

      // Product dimension
      setDimension1(response?.data?.product_details[0]?.dimension_x);
      setDimension2(response?.data?.product_details[0]?.dimension_y);
      setDimension3(response?.data?.product_details[0]?.dimension_z);
      setPackageDimension1(
        response?.data?.product_details[0]?.package_dimension_x
      );
      setPackageDimension2(
        response?.data?.product_details[0]?.package_dimension_y
      );
      setPackageDimension3(
        response?.data?.product_details[0]?.package_dimension_z
      );
      setWeight(response?.data?.product_details[0]?.weight);
      setProductWeight(response?.data?.product_details[0]?.product_weight);
      setDiameter(response?.data?.product_details[0]?.diameter);
      setShortDescription(
        response?.data?.product_details[0]?.short_description[0]
      );
      setLongDescription(response?.data?.product_details[0]?.description);

      // Categories
      if (response?.data?.categories1) {
        setCategory({
          value: response?.data?.categories1[0]?.id,
          label: response?.data?.categories1[0]?.name,
        });
        getSubCategoryData(response?.data?.categories1[0]?.id);
      }
      if (response?.data?.categories2) {
        setSubCategory({
          value: response?.data?.categories2[0]?.id,
          label: response?.data?.categories2[0]?.name,
        });
        getSubSubCategoryData(response?.data?.categories2[0]?.id);
      }
      if (response?.data?.categories) {
        setSubSubCategory({
          value: response?.data?.categories[0]?.id,
          label: response?.data?.categories[0]?.name,
        });
      }

      // Set tags
      setTags(
        response?.data?.style_tags ? response?.data?.style_tags?.split(',') : []
      );

      // Image
      const previewImages = [];
      if (response?.data?.product_details[0]?.product_images) {
        for (
          let i = 0;
          i < response?.data?.product_details[0]?.product_images.length;
          i++
        ) {
          const el = response?.data?.product_details[0]?.product_images[i];
          if (el.full_image_url !== '') {
            let base64File = await getBase64FromUrlV3(el.full_image_url);

            //console.log(`IMG-${i}:`, base64File);

            if (base64File !== '' && base64File !== undefined) {
              previewImages.push({
                name: 'response-photo.jpg',
                size: '-',
                preview: el.full_image_url + '?tsx=' + tsx,
                file: base64File,
                extension: 'png',
              });
            }
          }
        }
      }

      setSamplePreview(previewImages);
    }
  };

  const getVariants = async () => {
    const response = await variantList(id, searchVariant);
    if (response?.message === 'Success') {
      setVariants(response?.data);
    }
  };

  const handleSearchDataVariant = (e) => {
    if ($(e.currentTarget).prop('id') === 'button-search-variants') {
      setSearchVariant(
        $(e.currentTarget).parent().siblings('input#search-field').val()
      );
    }

    if (e?.key === 'Enter') {
      setSearchVariant($('input#search-field').val());
    }
  };

  const handleResetSearchVariant = () => {
    $('input#search-field').val('');
    setSearchVariant('');
  };

  const handleChangeDiscontValue = (e) => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
      if (
        parseInt(String(e.target.value).replaceAll('.', '')) >
        parseInt(String(price).replaceAll('.', ''))
      ) {
        setDiscount(0);
      } else {
        setDiscount(e.target.value);
      }
    } else {
      if (isNaN(e.target.value)) {
        setDiscount(0);
      } else if (e.target.value > 100) {
        setDiscount(100);
      } else {
        setDiscount(e.target.value);
      }
    }
  };

  const handleKeyupDiscountValue = (e) => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
      setDiscount(formatMoney(e.target.value.replaceAll('.', ''), ''));
    } else {
      setDiscount(e.target.value < 0 ? 0 : e.target.value);
    }
  };

  const handleChangeDiscountType = (e) => {
    setSelectedDiscountType(e.target.value);

    if (e.target.value === 1 || e.target.value === '1') {
      setDiscount(formatMoney(String(discount).replaceAll('.', ''), ''));
    } else {
      setDiscount(String(discount).replaceAll('.', ''));
    }
  };

  const getListBrand = async () => {
    const responseOwnBrand = await getVendorBrand();
    if (
      responseOwnBrand.message === 'Success' &&
      responseOwnBrand.data.length > 0
    ) {
      let options = [];
      responseOwnBrand.data.map((item) => {
        options.push({
          value: item.id,
          label: item.name,
          img: item.image_url,
          tags: item.tags,
          checked: item.checked,
        });
        return;
      });
      setBrandList(options);
    }
  };

  const handleOnChangeBrand = (e) => {
    setSelectedBrand(e);
  };

  const formatOptionLabel = ({ value, label, img }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 11,
      }}
    >
      <span>{label}</span>
    </div>
  );

  const customStyles = {
    brand: {
      control: (provided, state) => ({
        ...provided,
        width: 150,
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    },
  };

  const showInputError = (elmtId, error) => {
    if (error) {
      $('#' + elmtId).css('border-color', 'red');
    } else {
      $('#' + elmtId).css('border-color', '#ced4da');
    }
  };

  useEffect(() => {
    if (selectedDiscountType === 1 || selectedDiscountType === '1') {
    } else {
      if (discount > 100) {
        setDiscount(100);
      }
    }
  }, [selectedDiscountType]);

  useEffect(() => {
    // Set Room Type List
    let dataSelected = [];
    roomTypeListTemp?.forEach((roomTypeData) => {
      let findRoom = roomTypeOptions.find(
        (item) => item.label === roomTypeData
      );
      if (findRoom) {
        dataSelected.push(findRoom);
      }
    });
    setRoomType(dataSelected);
  }, [roomTypeOptions, roomTypeListTemp]);

  useEffect(() => {
    getRoomTypeData();
    getListBrand();

    // if edit
    if (id !== undefined) {
      setIsEdit(true);
      getDetailProduct();
      getVariants();
    }
  }, []);

  useEffect(() => {
    getCategoryData();
  }, []);

  useEffect(() => {
    getVariants();
  }, [searchVariant]);

  useEffect(() => {
    // show hide box image upload
    if (samplePreview.length >= 10) {
      setShowUploadInput(false);
    } else {
      if (showUploadInput) {
        return;
      }
      setShowUploadInput(true);
    }
  }, [samplePreview]);

  const submitForm = async (event) => {
    props.setLoading(true);
    try {
      event.preventDefault();

      let filePhotos = [];
      samplePreview.forEach((el) => {
        filePhotos.push(el.file);
      });
      setPhotos(filePhotos);

      // set room_type_payload
      let roomTypePayload = [];
      roomType.forEach((el) => {
        roomTypePayload.push(el.label);
      });

      const payload = {
        name: name,
        meta_title: metaTitle,
        meta_keyword: metaKeyword,
        meta_description: metaDescription,
        room_type: roomTypePayload,
        product_style_tag: tags.toString(),
        category_id: String(category.value),
        sub_category_id: String(subCategory.value),
        sub_sub_category_id: subSubCategory?.value
          ? String(subSubCategory.value)
          : null,
        sku: sku,
        brand_id: String(selectedBrand?.value),
        weight: parseFloat(String(weight).replaceAll(',', '.')),
        product_weight: parseFloat(String(productWeight).replaceAll(',', '.')),
        diameter: diameter === '' ? '0' : String(diameter).replaceAll(',', '.'),
        dimension1: parseFloat(String(dimension1).replaceAll(',', '.')),
        dimension2: parseFloat(String(dimension2).replaceAll(',', '.')),
        dimension3: parseFloat(String(dimension3).replaceAll(',', '.')),
        package_dimension1: parseFloat(
          String(packageDimension1).replaceAll(',', '.')
        ),
        package_dimension2: parseFloat(
          String(packageDimension2).replaceAll(',', '.')
        ),
        package_dimension3: parseFloat(
          String(packageDimension3).replaceAll(',', '.')
        ),
        price: parseInt(String(price).replaceAll('.', '')),
        discount: discount ? parseInt(String(discount).replaceAll('.', '')) : 0,
        discount_type: selectedDiscountType
          ? parseInt(selectedDiscountType)
          : 0,
        from_date: discountDateFrom ? moment(discountDateFrom).format() : null,
        to_date: discountDateTo ? moment(discountDateTo).format() : null,
        from_time: discountTimeFrom,
        to_time: discountTimeTo,
        warranty: parseInt(selectedWarrantyOwner),
        warranty_period: warrantyPeriod ? parseInt(warrantyPeriod) : 0,
        warranty_description: warrantyDescription ? warrantyDescription : '',
        short_description: [shortDescription],
        long_description: longDescription,
        is_preorder: isPreOrder,
        po_duration: poDuration ? parseInt(poDuration) : 0,
        is_fragile: isFragile,
        wood_package: woodPackage,
        is_volumetric: isVolumetric,
        images: filePhotos,
      };

      let response;
      if (isEdit) {
        payload.id = id;
        response = await editItems(payload);
      } else {
        response = await addItems(payload);
      }
      if (response?.message === 'Success create new record') {
        if (isEdit) {
          IsAlert.fire({
            title: 'Success',
            text: 'Update product success.',
            icon: 'success',
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText: 'Confirm',
          }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
              navigate('/items');
            }
          });
        } else {
          navigate(`/items/add-new/variant/add-new/${response?.data?.id}`, {
            state: { data: response.data, payloadProduct: payload },
          });
        }
        props.setLoading(false);
      } else {
        IsAlert.fire({
          title: 'Failed',
          text: response?.message ?? 'Something when wrong.',
          icon: 'error',
          showCancelButton: false,
          focusConfirm: true,
          confirmButtonText: 'Confirm',
        });
      }
      props.setLoading(false);
      return;
    } catch (error) {
      props.setLoading(false);
      console.error(error);
    }
  };

  /*
    useEffect(() => {
        console.log("CURRENT VAL:", samplePreview);
    }, [samplePreview]);
    */

  return (
    <div className="container-fluid mb-4 px-0 tab-navigasi">
      <div className="mb-4">
        <a onClick={() => navigate(`/items`)} className="fs-6 fw-bold">
          <img
            src={'/dist/img/custom/caret-left-light.svg'}
            className="icon-4 me-2"
            alt="caret-left-light"
          />
          {!isEdit && 'Add Product'}
          {isEdit && 'Edit Product'}
        </a>
      </div>
      <div className="w-100 pb-3 pb-lg-4 mb-3 mb-lg-4 border-bottom border-lg-0">
        <div className="w-100 swiper-nav swiper">
          <ul
            className="nav nav-pills ff2 nav-wrap swiper-wrapper"
            id="pills-tab"
            role="tablist"
          >
            {listVarianOnly ? (
              <li className="nav-item swiper-slide" role="presentation">
                <button
                  disabled={!isEdit}
                  className={
                    isShowVariant || listVarianOnly
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                  id="pills-variants-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-variants"
                  type="button"
                  role="tab"
                  aria-controls="pills-variants"
                  aria-selected="false"
                >
                  Variants
                </button>
              </li>
            ) : (
              <>
                <li className="nav-item swiper-slide" role="presentation">
                  <button
                    className={
                      isShowVariant || listVarianOnly
                        ? 'nav-link'
                        : 'nav-link active'
                    }
                    id="pills-information-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-information"
                    type="button"
                    role="tab"
                    aria-controls="pills-information"
                    aria-selected="true"
                  >
                    Information
                  </button>
                </li>
                <li className="nav-item swiper-slide" role="presentation">
                  <button
                    disabled={!(name && roomType && category && subCategory)}
                    className="nav-link"
                    id="pills-information-detail-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-information-detail"
                    type="button"
                    role="tab"
                    aria-controls="pills-information-detail"
                    aria-selected="false"
                  >
                    Information Detail
                  </button>
                </li>
                <li className="nav-item swiper-slide" role="presentation">
                  <button
                    disabled={
                      !(
                        sku &&
                        dimension1 &&
                        dimension2 &&
                        dimension3 &&
                        category &&
                        packageDimension1 &&
                        packageDimension2 &&
                        packageDimension3 &&
                        weight &&
                        productWeight &&
                        price &&
                        selectedBrand
                      ) ||
                      parseInt(weight) < parseInt(productWeight) ||
                      price <= 0 ||
                      weight <= 0 ||
                      productWeight <= 0
                    }
                    className="nav-link"
                    id="pills-photos-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-photos"
                    type="button"
                    role="tab"
                    aria-controls="pills-photos"
                    aria-selected="false"
                  >
                    Photos
                  </button>
                </li>
                <li className="nav-item swiper-slide" role="presentation">
                  <button
                    disabled={!samplePreview[0]}
                    className=" nav-link"
                    id="pills-description-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-description"
                    type="button"
                    role="tab"
                    aria-controls="pills-description"
                    aria-selected="false"
                  >
                    Description
                  </button>
                </li>
              </>
            )}
          </ul>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>

      <div className="tab-content" id="pills-tabContent">
        {/* <!-- Information --> */}
        <div
          className={
            isShowVariant || listVarianOnly
              ? 'tab-pane fade'
              : 'tab-pane fade show active'
          }
          id="pills-information"
          role="tabpanel"
          aria-labelledby="pills-information-tab"
        >
          <div className="fs-5 fw-bold mt-2 mb-4">Name & Category</div>
          <div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Product Name<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  placeholder="Input Product Name"
                  required
                />
              </div>
            </div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label fw-semibold">Room Type</label>
                {/* <select className="form-select js-basic-single">
                                    <option value="1">Room Type 1</option>
                                    <option value="2">Room Type 2</option>
                                    <option value="3">Room Type 3</option>
                                    <option value="4">Room Type 4</option>
                                    <option value="5">Room Type 5</option>
                                    <option value="6">Room Type 6</option>
                                    <option value="7">Room Type 7</option>
                                    <option value="8">Room Type 8</option>
                                    <option value="9">Room Type 9</option>
                                    <option value="10">Room Type 10</option>
                                </select>                                                           */}
                <Select
                  isMulti
                  className="basic-single"
                  classNamePrefix="select"
                  value={roomType ?? ''}
                  options={roomTypeOptions}
                  onChange={handleRoomTypeChange}
                  styles={{ zIndex: 1 }}
                  menuPlacement="auto"
                  isOptionDisabled={() => roomType.length >= 3}
                />
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label fw-semibold">
                  Product Style Tagging
                </label>

                <div className="form-control">
                  {tags.map((tag) => (
                    <span key={tag} className="tag">
                      {tag}
                      <button
                        className="transparent-button"
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: 'inherit',
                          cursor: 'pointer',
                          fontSize: '1em',
                          fontWeight: 'bold',
                          outline: 'none',
                          padding: '0.5em 1em',
                          textDecoration: 'none',
                        }}
                        type="button"
                        onClick={() => handleRemoveTag(tag)}
                      >
                        x
                      </button>
                    </span>
                  ))}
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    style={{ border: 'none', outline: 'none' }}
                    placeholder="Type & Enter"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-3">
              <div className="col-12 col-sm-6 col-lg-4 mb-3 mb-lg-0 pe-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Category<div className="text-danger ps-1">*</div>
                </label>
                {/* <select className="form-select js-basic-single">
                                    <option value="1">Category 1</option>
                                    <option value="2">Category 2</option>
                                    <option value="3">Category 3</option>
                                    <option value="4">Category 4</option>
                                    <option value="5">Category 5</option>
                                    <option value="6">Category 6</option>
                                    <option value="7">Category 7</option>
                                    <option value="8">Category 8</option>
                                    <option value="9">Category 9</option>
                                    <option value="10">Category 10</option>
                                </select>                                                           */}

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={category}
                  value={category}
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                  menuPlacement="auto"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 ps-sm-2 px-lg-1 mb-3 mb-sm-0">
                <label className="form-label d-flex fw-semibold">
                  Sub Category<div className="text-danger ps-1">*</div>
                </label>
                {/* <select className="form-select js-basic-single">
                                    <option value="1">Sub Category 1</option>
                                    <option value="2">Sub Category 2</option>
                                    <option value="3">Sub Category 3</option>
                                    <option value="4">Sub Category 4</option>
                                    <option value="5">Sub Category 5</option>
                                    <option value="6">Sub Category 6</option>
                                    <option value="7">Sub Category 7</option>
                                    <option value="8">Sub Category 8</option>
                                    <option value="9">Sub Category 9</option>
                                    <option value="10">Sub Category 10</option>
                                </select>  */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={subCategory}
                  value={subCategory}
                  options={subCategoryOptions}
                  onChange={handleSubCategoryChange}
                  menuPlacement="auto"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 ps-lg-2">
                <label className="form-label d-flex fw-semibold">
                  Sub Category
                </label>
                {/* <select className="form-select js-basic-single">
                                    <option value="1">Sub Category 1</option>
                                    <option value="2">Sub Category 2</option>
                                    <option value="3">Sub Category 3</option>
                                    <option value="4">Sub Category 4</option>
                                    <option value="5">Sub Category 5</option>
                                    <option value="6">Sub Category 6</option>
                                    <option value="7">Sub Category 7</option>
                                    <option value="8">Sub Category 8</option>
                                    <option value="9">Sub Category 9</option>
                                    <option value="10">Sub Category 10</option>
                                </select>  */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={subSubCategory}
                  value={subSubCategory}
                  options={subSubCategoryOptions}
                  onChange={handleSubSubCategoryChange}
                  menuPlacement="auto"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                <button
                  disabled={!(name && roomType && category && subCategory)}
                  type="button"
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  onClick={() => {
                    $('#pills-information-detail-tab').click();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Information Detail --> */}
        <div
          className="tab-pane fade"
          id="pills-information-detail"
          role="tabpanel"
          aria-labelledby="pills-information-detail-tab"
        >
          <div className="fs-5 fw-bold mb-4">Product Detail</div>
          <div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Product SKU<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={sku}
                  onChange={(event) => {
                    setSku(event.target.value);
                  }}
                  required
                />
              </div>
              {/* <div className="col-12 col-sm-6 ps-sm-2">
                                <label className="form-label d-flex fw-semibold">Product Owner<div className="text-danger ps-1">*</div></label>
                                <select value={selectedProductOwner} onChange={(event) => {setSelectedProductOwner(event.target.value) }} required  className= "form-select">
                                    <option value="">Own Product</option>
                                    <option value="">Consignment</option>
                                </select>                                                            
                            </div> */}
            </div>
            <div className="d-sm-flex mb-3">
              {/* <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                                <label className="form-label d-flex fw-semibold">Brand<div className="text-danger ps-1">*</div></label>
                                <select className="form-select js-basic-single">
                                    <option value="1">Brand 1</option>
                                    <option value="2">Brand 2</option>
                                    <option value="3">Brand 3</option>
                                    <option value="4">Brand 4</option>
                                    <option value="5">Brand 5</option>
                                    <option value="6">Brand 6</option>
                                    <option value="7">Brand 7</option>
                                    <option value="8">Brand 8</option>
                                    <option value="9">Brand 9</option>
                                    <option value="10">Brand 10</option>
                                </select>                                                      
                            </div> */}
              <div className="col-12 col-sm-12">
                <label className="form-label fw-semibold">Warranty</label>
                <select
                  value={selectedWarrantyOwner}
                  onChange={(event) => {
                    setSelectedWarrantyOwner(event.target.value);
                  }}
                  className="form-select"
                >
                  <option value="0">No Warranty</option>
                  <option value="1">Seller Warranty</option>
                  <option value="2">Distributor Warranty</option>
                </select>
              </div>
            </div>
            {parseInt(selectedWarrantyOwner) !== 0 && (
              <>
                <div className="d-sm-flex mb-3">
                  <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                    <label className="form-label d-flex fw-semibold">
                      Warranty Period (Days)
                      <div className="text-danger ps-1">*</div>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={warrantyPeriod}
                      onChange={(event) => {
                        setWarrantyPeriod(
                          event.target.value < 0 ? 0 : event.target.value
                        );
                      }}
                      min={0}
                      required
                    />
                  </div>
                </div>
                <div className="d-sm-flex mb-3">
                  <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                    <label className="form-label d-flex fw-semibold">
                      Warranty Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={warrantyDescription}
                      onChange={(event) => {
                        setWarrantyDescription(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-3 px-1">
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Product Length(CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={dimension1}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setDimension1(0);
                    } else if (event.target.value < 0) {
                      setDimension1(0);
                    } else {
                      setDimension1(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Product Width (CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={dimension2}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setDimension2(0);
                    } else if (event.target.value < 0) {
                      setDimension2(0);
                    } else {
                      setDimension2(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Product Height (CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={dimension3}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setDimension3(0);
                    } else if (event.target.value < 0) {
                      setDimension3(0);
                    } else {
                      setDimension3(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Packaging Length (CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={packageDimension1}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setPackageDimension1(0);
                    } else if (event.target.value < 0) {
                      setPackageDimension1(0);
                    } else {
                      setPackageDimension1(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Packaging Width (CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={packageDimension2}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setPackageDimension2(0);
                    } else if (event.target.value < 0) {
                      setPackageDimension2(0);
                    } else {
                      setPackageDimension2(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Packaging Height (CM)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={packageDimension3}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setPackageDimension3(0);
                    } else if (event.target.value < 0) {
                      setPackageDimension3(0);
                    } else {
                      setPackageDimension3(event.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Total Weight (KG)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  id="input-weight"
                  type="text"
                  className="form-control"
                  value={weight}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setWeight(0);
                      showInputError('input-weight', true);
                    } else if (event.target.value < 0) {
                      setWeight(0);
                      showInputError('input-weight', true);
                    } else {
                      setWeight(event.target.value);

                      let w = parseInt($('#input-weight').val(), 10);
                      let pw = parseInt($('#input-product-weight').val(), 10);

                      if (pw > w) {
                        showInputError('input-weight', true);
                        showInputError('input-product-weight', true);
                      } else {
                        showInputError('input-weight', false);
                        showInputError('input-product-weight', false);
                      }
                    }
                  }}
                  placeholder="Packaging & Product"
                  required
                />

                <small>
                  <i className="">
                    *) The values of Total Weight cannot be less than the values
                    of Product Weight
                  </i>
                </small>
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Product Weight (KG)<div className="text-danger ps-1">*</div>
                </label>
                <input
                  id="input-product-weight"
                  type="text"
                  className="form-control"
                  value={productWeight}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setProductWeight(0);
                      showInputError('input-product-weight', true);
                    } else if (event.target.value < 0) {
                      setProductWeight(0);
                      showInputError('input-product-weight', true);
                    } else {
                      setProductWeight(event.target.value);

                      let w = parseInt($('#input-weight').val(), 10);
                      let pw = parseInt($('#input-product-weight').val(), 10);

                      if (pw > w) {
                        showInputError('input-weight', true);
                        showInputError('input-product-weight', true);
                      } else {
                        showInputError('input-weight', false);
                        showInputError('input-product-weight', false);
                      }
                    }
                  }}
                  required
                />
              </div>
              <div className="p-2">
                <label className="form-label fw-semibold">Diameter (CM)</label>
                <input
                  type="text"
                  className="form-control"
                  value={diameter}
                  onChange={(event) => {
                    if (isNaN(event.target.value)) {
                      setDiameter(0);
                    } else if (event.target.value < 0) {
                      setDiameter(0);
                    } else {
                      setDiameter(event.target.value);
                    }
                  }}
                />
              </div>
              <div className="p-2">
                <label className="form-label d-flex fw-semibold">
                  Brand<div className="text-danger ps-1">*</div>
                </label>
                <Select
                  className="basic-single w-100"
                  classNamePrefix="select"
                  placeholder="Brand"
                  name="form-category"
                  value={selectedBrand}
                  options={brandList}
                  isSearchable={false}
                  onChange={handleOnChangeBrand}
                  formatOptionLabel={formatOptionLabel}
                  styles={customStyles.star}
                  menuPortalTarget={document.body}
                  required
                />
              </div>
            </div>

            <div className="fs-5 fw-bold mb-4 mt-4">Price</div>
            <div className="d-sm-flex">
              <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label d-flex fw-semibold">
                  Sell Price<div className="text-danger ps-1">*</div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                  onKeyUp={(event) =>
                    setPrice(
                      formatMoney(event.target.value.replaceAll('.', ''), '')
                    )
                  }
                  required
                />
              </div>
            </div>
            <small>
              <i className="">
                *) Sell price is required and should be greater than 0
              </i>
            </small>

            <div className="d-sm-flex mb-3 mt-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label fw-semibold">Discount Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={discount}
                  onChange={handleChangeDiscontValue}
                  onKeyUp={handleKeyupDiscountValue}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label fw-semibold">Discount Type</label>
                <select
                  value={selectedDiscountType}
                  onChange={handleChangeDiscountType}
                  className="form-select"
                >
                  <option value="1">Value</option>
                  <option value="2">Percent</option>
                </select>
              </div>
            </div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label fw-semibold">
                  Discount Date From
                </label>
                <div
                  className="input-group bg-white border rounded password date w-100"
                  id="datepicker"
                >
                  {/* <input type="text" className="form-control border-0 box-shadow-focus-0" aria-describedby="basic-addon1" />
                                    <span className="input-group-append">
                                        <span className="input-group-text bg-white border-0" id="basic-addon1">
                                        <img src="../dist/img/custom/calendar.svg" className="icon-3 pt-1"></img>
                                        </span>
                                    </span> */}
                  <DatePicker
                    className="form-control border-0 box-shadow-focus-0"
                    selected={discountDateFrom}
                    onChange={(date) => setDiscountDateFrom(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={discountDateTo}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label fw-semibold">
                  Discount Date To
                </label>
                <div
                  className="input-group bg-white border rounded password date w-100"
                  id="datepicker2"
                >
                  {/* <input type="text" className="form-control border-0 box-shadow-focus-0" aria-describedby="basic-addon1" /> */}
                  <DatePicker
                    className="form-control border-0 box-shadow-focus-0"
                    selected={discountDateTo}
                    onChange={(date) => setDiscountDateTo(date)}
                    dateFormat="dd/MM/yyyy"
                    minDate={discountDateFrom}
                  />
                  {/* <span className="input-group-append">
                                        <span className="input-group-text bg-white border-0" id="basic-addon1">
                                        <img src="../dist/img/custom/calendar.svg" className="icon-3 pt-1"></img>
                                        </span>
                                    </span> */}
                </div>
              </div>
            </div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label fw-semibold">
                  Discount Time From
                </label>
                {/* <input type="text" className="form-control border-0 box-shadow-focus-0" />
                                    <span className="input-group-append">
                                        <span className="input-group-text bg-white border-0" id="basic-addon1">
                                        <img src="../dist/img/custom/clock.svg" className="icon-2 pt-1"></img>
                                        </span>
                                    </span> */}
                {/* <TimePicker
                                            className="form-control border-0 box-shadow-focus-0"
                                            onChange={(time) => setDiscountTimeFrom(time)}
                                            value={discountTimeFrom}
                                            format="h:mm a"
                                            
                                        /> */}
                <Datetime
                  className="form-control border-0 box-shadow-focus-0 p-0"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  inputProps={{ readOnly: true }}
                  onChange={(time) => setDiscountTimeFrom(time.format('HH:mm'))}
                  value={discountTimeFrom}
                />
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <label className="form-label fw-semibold">
                  Discount Time To
                </label>
                {/* <input type="text" className="form-control border-0 box-shadow-focus-0" />
                                    <span className="input-group-append">
                                        <span className="input-group-text bg-white border-0" id="basic-addon1">
                                        <img src="../dist/img/custom/clock.svg" className="icon-2 pt-1"></img>
                                        </span>
                                    </span> */}
                {/* <TimePicker
                                            className="form-control border-0 box-shadow-focus-0"
                                            onChange={(time) => setDiscountTimeTo(time)}
                                            value={discountTimeTo}
                                            format="h:mm a"
                                        /> */}
                <Datetime
                  className="form-control border-0 box-shadow-focus-0 p-0"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  inputProps={{ readOnly: true }}
                  onChange={(time) => setDiscountTimeTo(time.format('HH:mm'))}
                  value={discountTimeTo}
                />
              </div>
            </div>
            <div className="fs-5 fw-bold mb-4 mt-4">Other Information</div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <div className="d-flex">
                  <div>
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      checked={isPreOrder}
                      onChange={(event) => {
                        setIsPreOrder(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="fw-semibold">Made To Order</div>
                    <div className="">
                      <div className="fw-semibold mt-3">
                        PO Durations (Days)
                      </div>
                      <div className="fz-14 mt-2">
                        Fill by days needed for item to be produced
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control"
                          value={poDuration}
                          onChange={(event) => {
                            setPoDuration(
                              event.target.value < 0 ? 0 : event.target.value
                            );
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <div className="d-flex">
                  <div>
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      checked={isFragile}
                      onChange={(event) => {
                        setIsFragile(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="fw-semibold">Product Is Fragile</div>
                    <div className="fz-14 mt-2">
                      Check if this item is fragile (Will use additional
                      packaging from 3rd party shipment)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                <div className="d-flex">
                  <div>
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      checked={isVolumetric}
                      onChange={(event) => {
                        setIsVolumetric(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="fw-semibold">
                      Product Is Using Volumetric Weight
                    </div>
                    <div className="fz-14 mt-2">
                      Check if this item is using volumetric weight (Packaging
                      dimension & dimension must be filled)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 ps-sm-2">
                <div className="d-flex">
                  <div>
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      checked={woodPackage}
                      onChange={(event) => {
                        setWoodPackage(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="fw-semibold">
                      Product Need To Use Additional Wood Packaging
                    </div>
                    <div className="fz-14 mt-2">
                      Check if this item need to use additional wood packaging
                      from 3rd party shipment
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-sm-flex mb-3">
                            <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                                <div className="d-flex">
                                    <div>
                                        <input className="form-check-input me-3" type="checkbox" />
                                    </div>
                                    <div className="">
                                        <div className="fw-semibold">
                                            Item Need To Use Additional Wood Packaging
                                        </div>
                                        <div className="fz-14 mt-2">
                                            Check if this item need to use additional wood packaging from 3rd party shipment
                                        </div>
                                    </div>
                                </div>                                                        
                            </div>
                        </div> */}
            <div className="d-flex justify-content-between">
              <div className="col-12 col-sm-4 ps-sm-2">
                <button
                  type="button"
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  onClick={() => {
                    $('#pills-information-tab').click();
                  }}
                >
                  Previous
                </button>
              </div>
              <div className="col-12 col-sm-4 ps-sm-2 text-end">
                <button
                  type="button"
                  className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                  disabled={
                    !(
                      sku &&
                      dimension1 &&
                      dimension2 &&
                      dimension3 &&
                      category &&
                      packageDimension1 &&
                      packageDimension2 &&
                      packageDimension3 &&
                      weight &&
                      productWeight &&
                      price &&
                      selectedBrand
                    ) ||
                    parseInt(weight) < parseInt(productWeight) ||
                    price <= 0 ||
                    weight <= 0 ||
                    productWeight <= 0
                  }
                  onClick={() => {
                    $('#pills-photos-tab').click();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Photos --> */}
        <div
          className="tab-pane fade"
          id="pills-photos"
          role="tabpanel"
          aria-labelledby="pills-photos-tab"
        >
          <div className="fs-5 fw-bold mt-2 mb-4">Photos</div>
          {/* <div className="w-100 px-2">
            <label htmlFor="file-upload" className="upload-file-custom">
              <div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <div className="img-wrap">
                    <img
                      src="/dist/img/custom/image.svg"
                      className="w-4rem"
                      alt="img-upload"
                    />
                  </div>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                  <p className="mb-0 text-center">
                    Drop your photos here, or <strong>browse</strong>
                  </p>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center mt-1">
                  <p className="mb-0 text-center text-secondary fz-14">
                    Max. 5MB per file with format .jpeg, .jpg, .png (Max. 10
                    files)
                  </p>
                </div>
              </div>
            </label>
            <input
              className="visually-hidden"
              id="file-upload"
              type="file"
              accept=".jpeg,.jpg,.png"
              onChange={handleUpload}
              disabled={samplePreview.length >= 10}
            />
          </div> */}
          {showUploadInput ? (
            <FileUpload
              upload={handleUpload}
              samplePreviews={samplePreview}
              maxFile={10}
              maxSizePerFile={40}
            />
          ) : null}

          <div className="d-flex justify-content-center">
            <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mt-3 me-0">
              {samplePreview && samplePreview.length > 0 && (
                <>
                  {samplePreview.map((image, index) => {
                    return (
                      <div key={index}>
                        <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="img-wrap w-5rem ar-square rounded-3 overflow-hidden d-flex align-items-center justify-content-center">
                              <div className="w-100 h-100">
                                <img
                                  id="imageId"
                                  src={image.preview}
                                  className="object-fit-cover w-5rem ar-square"
                                  alt="Preview"
                                />
                              </div>
                            </div>
                            <div className="col ms-3">
                              <p className="mb-0 text-wrap-1">{image.name}</p>
                              <div className="fz-14 text-secondary mt-3">
                                {image.size} Mb
                              </div>
                            </div>
                          </div>
                          <button className="btn p-0">
                            {/* <img src="/dist/img/custom/x.svg" className="icon-3" alt="img-delete" onClick={clearImageSelected.bind(this, index)} /> */}
                            <img
                              src="/dist/img/custom/x.svg"
                              className="icon-3"
                              alt="img-delete"
                              onClick={clearImageSelected.bind(this, index)}
                            />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          {/* <div className="d-flex justify-content-end px-2 mt-2">
                            <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                                <button type="button" className="btn w-100 fw-semibold btn-light-gray px-5 mt-3 ask"> 
                                    Next
                                </button>  
                                <button type="submit" className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask" >
                                    Next
                                </button>
                            </div>
                        </div> */}
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-4 ps-sm-2">
              <button
                type="button"
                className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                onClick={() => {
                  $('#pills-information-detail-tab').click();
                }}
              >
                Previous
              </button>
            </div>
            <div className="col-12 col-sm-4 ps-sm-2 text-end">
              <button
                type="button"
                className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                disabled={!samplePreview[0]}
                onClick={() => {
                  $('#pills-description-tab').click();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Description --> */}
        <div
          className="tab-pane fade"
          id="pills-description"
          role="tabpanel"
          aria-labelledby="pills-description-tab"
        >
          <div className="fs-5 fw-bold mb-4">Description</div>
          <div>
            <div className="mb-3">
              <label className="form-label fw-semibold">
                Short Description
              </label>
              <input
                type="text"
                className="form-control"
                value={shortDescription}
                onChange={(event) => {
                  setShortDescription(event.target.value);
                }}
              />
            </div>
            <div className="d-sm-flex mb-3">
              <div className="col-12 col-sm-12 mb-3 mb-sm-0 pe-sm-2">
                <label className="form-label fw-semibold">
                  Detail Description
                </label>

                <CKEditor
                  editor={ClassicEditor}
                  data={longDescription}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setLongDescription(data);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                    ],
                  }}
                />
              </div>
              {/* <div className="col-12 col-sm-6 ps-sm-2">
                                <label className="form-label fw-semibold">Size Guide</label>
                                <textarea className="form-control" rows="3"></textarea>                                                        
                            </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-4 ps-sm-2">
              <button
                type="button"
                className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                onClick={() => {
                  $('#pills-photos-tab').click();
                }}
              >
                Previous
              </button>
            </div>
            <div className="col-12 col-sm-4 ps-sm-2 text-end">
              <button
                type="button"
                className="btn w-50 fw-semibold btn-fill px-5 mt-3 ask"
                onClick={submitForm}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Variants --> */}
        <div
          className={
            isShowVariant || listVarianOnly
              ? 'tab-pane fade show active'
              : 'tab-pane fade'
          }
          id="pills-variants"
          role="tabpanel"
          aria-labelledby="pills-variants-tab"
        >
          <div className="fs-5 fw-bold mt-2 mb-4">Variants List</div>
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-end align-items-center">
            <div className="pe-0 pe-lg-4 w-lg-100 mb-2 mb-lg-0">
              <div className="mb-2 mb-lg-0">
                <div
                  className="input-group search-product flex-nowrap border rounded-pill overflow-hidden w-lg-100"
                  style={{ width: '200px' }}
                >
                  <input
                    type="text"
                    className="form-control border-0 fz-14 box-shadow-focus-0"
                    placeholder="Search by sku"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    onKeyUp={handleSearchDataVariant}
                    id="search-field"
                  />
                  <div className="py-1 px-1">
                    <button
                      className="btn btn-secondary border-0 rounded-circle p-0 d-flex align-items-center justify-content-center"
                      type="button"
                      id="button-search-variants"
                      style={{ width: '1.8rem', height: '1.7rem' }}
                      onClick={handleSearchDataVariant}
                    >
                      <img
                        src="/dist/img/custom/search.svg"
                        style={{ width: '0.9rem' }}
                        className="filter-white"
                        alt="img-search"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-0 ps-lg-4 w-lg-100 border-start border-lg-0 d-flex flex-column-reverse flex-lg-row align-items-center">
              <button
                className="btn btn-line fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-2 mb-lg-0"
                onClick={handleResetSearchVariant}
              >
                Reset
              </button>
              <NavLink
                to={`/items/add-new/variant/add-new/${id}`}
                href="./add-variant.html"
                className="btn btn-fill fw-bold w-lg-100 me-0 me-lg-3 w-lg-100 mb-3 mb-lg-0"
              >
                Add Variant
              </NavLink>
            </div>
          </div>
          {/* <!-- Empty --> */}
          {/* <div className="w-100 empty-wrap d-flex align-items-center justify-content-center">
                        <div className="d-grid px-3 px-sm-0">
                            <div className="d-flex align-items-center justify-content-center">
                            <div className="img-wrap img-wrap-empty">
                                <img src="../dist/img/empty/empty-product.png" />
                            </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4">   
                            <div className="fs-6 fw-bold text-center">
                                No Variant Found!
                            </div>                                                       
                            </div>
                        </div>
                    </div> */}
          {/* <!-- Ada Isinya --> */}
          <div className="w-100 mt-3 overflow-auto flipped">
            <table
              className="table table-striped table-ordinary content-isi"
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Size</th>
                  <th>Color</th>
                  <th>Material</th>
                  <th>Finishing</th>
                  <th>Stock</th>
                  <th>
                    <span className="visually-hidden">Options</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {variants && variants.length > 0 && (
                  <>
                    {variants.map((variant, index) => {
                      return (
                        <tr key={index}>
                          <td>{variant?.sku}</td>
                          <td>{variant?.sizes}</td>
                          <td>{variant?.colors}</td>
                          <td>{variant?.material}</td>
                          <td>{variant?.finishing}</td>
                          <td>{variant?.total_stock}</td>
                          <td>
                            <div className="d-flex" style={{ gap: '8px' }}>
                              <a
                                className="btn btn-sm btn-info"
                                title="Add Stock"
                                data-bs-toggle="modal"
                                data-bs-target="#modalAddStock"
                                onClick={() => handleClickAddStock(variant?.id)}
                              >
                                <FontAwesomeIcon icon={faPlus} color="white" />
                              </a>
                              <NavLink
                                to={`/items/add-new/variant/add-new/${id}/${variant.id}`}
                                className="btn btn-sm btn-warning"
                                data-bs-toggle="tooltip"
                                title="Edit"
                              >
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  color="white"
                                />
                              </NavLink>
                              <a
                                className="btn btn-sm btn-danger"
                                data-bs-toggle="tooltip"
                                title="Delete"
                                onClick={() =>
                                  handleClickDeleteVariant(variant?.id)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} color="white" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-100 d-lg-flex justify-content-between align-items-center mt-4">
            <div>
              <span>
                Showing <strong>1-{variants.length}</strong> of{' '}
                <strong>{variants.length}</strong> items.
              </span>
            </div>
            <div className="mt-2 mt-lg-0">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <img
                          src="../dist/img/custom/caret-left.svg"
                          className="icon-hw-4"
                        />
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <img
                          src="../dist/img/custom/caret-left.svg"
                          className="icon-hw-4 fa-rotate-180"
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* Modal Add Stock */}
          <div
            className="modal fade"
            id="modalAddStock"
            tabIndex="-1"
            aria-labelledby="modalAddStockLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content p-3">
                <div className="modal-header border-0">
                  <h5>Add Stock</h5>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    value={inputAddStock}
                    onChange={(event) => {
                      if (isNaN(event.target.value)) {
                        setInputAddStock(0);
                      } else if (event.target.value < 0) {
                        setInputAddStock(0);
                      } else {
                        setInputAddStock(event.target.value);
                      }
                    }}
                  />
                </div>
                <div className="modal-footer border-0">
                  <div className="w-100 d-flex" style={{ gap: '10px' }}>
                    <button
                      type="button"
                      className="btn w-100 m-0 btn-danger fw-semibold"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn w-100 m-0 btn-fill fw-semibold"
                      data-bs-dismiss="modal"
                      onClick={handleAddStock}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
  };
};

export default connect(mapState, actionCreators)(AddItem);
