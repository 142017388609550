import React, { useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
  <div className="agreement-nav-group">
    <button
      onClick={() => zoomIn()}
      type="button"
      className="agreement-nav-item"
    >
      +
    </button>
    <button
      onClick={() => zoomOut()}
      type="button"
      className="agreement-nav-item"
    >
      -
    </button>
    <button
      onClick={() => resetTransform()}
      type="button"
      className="agreement-nav-item"
    >
      x
    </button>
  </div>
);

const ZoomPreviewComponent = ({ children }) => {
  const transformComponentRef = useRef(null);

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      ref={transformComponentRef}
      disabled
    >
      {(utils) => (
        <React.Fragment>
          <Controls {...utils} />
          <TransformComponent>{children}</TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ZoomPreviewComponent;
